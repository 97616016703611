<template>
    <div id="player-area" ref="player">
      <resize-observer @notify="handleResize"></resize-observer>
      <video id="video-player" controls crossorigin="anonymous" data-poster="">
        <source src="" type="video/mp4" />
      </video>
    </div>
</template>

<script>
// import { VIDEO_SERVER } from '@/constants'
// import { thumb } from '@/helpers/utils'
// import { API, Auth, Storage, graphqlOperation } from 'aws-amplify';
import Plyr from 'plyr';
// import { mapState } from 'vuex'
// import { getVideo } from '@/graphql/queries'

// import * as queries from '@/graphql/queries'


export default {
  data () {
    return {
      loading: false,
      errors: [],
      title: '',
      posterTime: '',
      published: ''
    }
  },

  watch: {
    // call again the method if the route changes
    '$route': 'getVideo'
  },
  created () {
    console.log('dynamic player created')
  },
  mounted () {
    console.log( 'PLAYER MOUNTED')

    const plyr = new Plyr('#video-player', {
      // debug: true,
      // controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'fullscreen'],
      blankVideo: 'https://cdn.plyr.io/static/blank.mp4',
      ratio: '16:9',
      invertTime: false,
      keyboard: { focused: true, global: false },
      displayDuration: true
    });

    plyr.once('ready', event => {
      console.log('************ PLYR READY ***********', event.detail.plyr)
      this.$store.commit('setPlayer', event.detail.plyr)
      this.getVideo ()

      const height = this.$refs.player.clientHeight
      this.$store.commit('setPlayerHeight', height)      
    });

  },
  beforeDestroy () {
    console.log('Destrying player')
    this.player.destroy()
  },  
  computed: {
    player () {
      return this.$store.state.player
    }
  },
  methods: {
    handleResize () {
      const height = this.$refs.player.clientHeight
      this.$store.commit('setPlayerHeight', height)
    },
    async getVideo () {
      console.log('GET VIDEO')
      // Don't switch video when changeing from chapter edit to video edit
      if (this.$store.state.currentVideo === undefined || this.$store.state.currentVideo.id !== this.$route.params.videoId) {
        const videoId = this.$route.params.videoId
        try {
          const video = await this.$store.dispatch('setCurrentVideo', videoId)
          console.log('VIDEO', video)
        } catch(err) {
          console.log(err)
        }
        await this.$store.dispatch('loadVideo')
      }
    }
  }
}
</script>

<style scoped>
  #player-area {
    position: relative;
  }
</style>
