<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-file-find"
        label="Søk"
        single-line
        hide-details
        @focus="disableKeys"
        @blur="enableKeys"
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="videos"
      :search="search"
      :items-per-page="150"
      sort-by="title"
      :loading="loading"
      hide-default-footer
    >

      <template v-if="res.errors && res.errors.length > 0" v-slot:top>
        <p>Noe gikk galt: {{res.errors[0].message}}</p>
      </template>

      <template v-slot:item="{ item }">
          <Video :video="item" />
      </template>

      <template v-if="true" v-slot:progress>
        <v-progress-linear color="blue" :height="10" indeterminate></v-progress-linear>
      </template>

    </v-data-table>

  </v-card>
</template>

<!-- BRUK TABLES! -->
<script>
// import { IMAGE_SERVER } from '@/constants'
import { thumb } from '@/helpers/utils'
// import VideoListVideo from './VideoListVideo'
// import EditVideo from './videoEdit'
import { API, graphqlOperation } from 'aws-amplify'

// import * as subscriptions from '@/graphql/subscriptions'
import * as queries from '@/graphql/queries'
// import { deleteVideo } from '@/graphql/mutations'
// listVideos, getVideo
// onCreateVideo, onDeleteVideo, createDeleteUpdateVideo
import Video from './videoNavListVideo'
// let deleteVideoSubscription, createdVideoSubscription

export default {
 name: 'VideoList',
 data () {
   return {
    search: '',
    headers: [
       {
         text: 'Tittel',
         value: 'title'
       },
      //  {
      //    text: 'Kategori',
      //    value: 'category.name'
      //  },
      //  { text: 'Handlinger', value: 'action', sortable: false }
     ],
     res: {},
     loading: true,
     videos: [],
     video: null
   }
 },
 components: {
   Video
 },
 created () {
  // this.logger = new this.$Amplify.Logger('VideoList_component')
  this.list()

  // deleteVideoSubscription = API.graphql(graphqlOperation(subscriptions.onDeleteVideo)).subscribe({
  //     next: (eventData) => {
  //       const videos = this.res.data.listVideoByTitle.items
  //       const deletedVideo = eventData.value.data.onDeleteVideo
  //       const checkVideo = video => { return deletedVideo.id === video.id }
  //       const index = videos.findIndex(checkVideo)
  //       this.res.data.listVideoByTitle.items.splice(index, 1) // mutate listVideo.items
  //     }
  // })
  // createdVideoSubscription = API.graphql(graphqlOperation(subscriptions.onCreateVideo)).subscribe({
  //   next: (eventData) => {
  //     const newVideo = eventData.value.data.onCreateVideo
  //     this.res.data.listVideoByTitle.items.push(newVideo)
  //   }
  // })
 },
 destroyed () {
  //  deleteVideoSubscription.unsubscribe()
  //  createdVideoSubscription.unsubscribe()
 },
 methods: {
    enableKeys () { this.$store.dispatch('enableKeys') },
    disableKeys () {
      console.log('disableKeys')
      this.$store.dispatch('disableKeys')
    },
    remove () {
      this.$emit('remove', this.video.id)
    },
    edit () {
      this.$emit('edit', this.video)
    },
    thumb (fileName, time, width) {
      return thumb(fileName, time, width)
    },
   async list () {
     let result
     try {
      result = await API.graphql(
        graphqlOperation(queries.listVideos, {
          limit: 300
        })
      )
      // console.log('videoNav.Vue, async list :\n', result)
      this.res = result
      this.data = result.data.listVideos.items
      this.loading = result.loading
      this.videos = result.data.listVideos.items
     } catch (e) {
       console.log('videoNav, async list err:\n',e)
       this.res = e
     }
   }
 }

}
</script>

<style>

</style>
