<template>
<div class="d-flex justify-center" id="player-controls-area">
  <div id="player-controls">
    <v-btn class="player-control" text dense small @mouseup.left="plyrTransport('rewind', 10)" :color="buttonColor">
      <v-icon>mdi-step-backward-2</v-icon>
    </v-btn>

    <v-btn class="player-control" text dense small @mouseup.left="plyrTransport('rewind', 1)" :color="buttonColor">
      <v-icon>mdi-step-backward</v-icon>
    </v-btn>

    <v-btn class="play-btn" text dense small @click="plyrTransport('play')" :color="buttonColor">
      <v-icon class="play-icon">mdi-play</v-icon>
    </v-btn>

    <v-btn text dense small @click="plyrTransport('pause')" :color="buttonColor">
      <v-icon>mdi-pause</v-icon>
    </v-btn>

    <v-btn text dense small @mouseup.left="plyrTransport('forward', 1)" :color="buttonColor">
      <v-icon>mdi-step-forward</v-icon>
    </v-btn>

    <v-btn text dense small @mouseup.left="plyrTransport('forward', 10)" :color="buttonColor">
      <v-icon>mdi-step-forward-2</v-icon>
    </v-btn>
  </div>
</div>
</template>

<!-- <button @click="plyrTransport('reversePlay', -1)"> Reverse play </button>
<button @click="plyrTransport('forward', 10/25)"> rew 10 fr </button>
<button @click="plyrTransport('rewind', 10/25)"> 10 fr fwr </button>
<button @click="plyrTransport('play')"> Play </button>
<button @click="plyrTransport('pause')"> Pause </button> -->


<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      buttonColor: 'black',
      frameRate: 25, // video.framerate
      intervalShuttle: null,
      intervalCleared: true
    }
  },
  created () {
    // window.addEventListener('keyup', this.doPlayerAction)
  },
  mounted () {

  },
  computed: {
    ...mapState(['playerKeysEnabled', 'playerPaused', 'player']),
    // player () {
    //   const player = document.getElementsByTagName('video')[0]
    //   console.log('PlayControlls PLAYER', player)
    //   return player
    // }
  },
  methods: {
    plyrTransport (action, value) {
      this.$store.commit('plyrTransport', { action , value })
    },
    // doPlayerAction (e) {
    //   if (this.playerKeysEnabled) {
    //     console.log('player', this.player)
    //     console.log('doing action:', e.code)
    //     switch (e.code) {
    //       case 'KeyK':
    //         if(this.playerPaused) {
    //           this.player.play()
    //         } else {
    //           this.player.pause()
    //         }
    //         break;
    //       case 'Space':
    //         console.log('Space')
    //         if(this.playerPaused) {
    //           this.player.play()
    //         } else {
    //           this.player.pause()
    //         }
    //         break;
    //       case 'KeyJ':
    //         this.shuttle (-10)
    //         break;
    //       case 'KeyL':
    //         this.shuttle(10)
    //         break;
    //       case 'Comma':
    //         this.skipFrames(-1)
    //         break;
    //       case 'Period':
    //         this.skipFrames(1)
    //         break;
    //       default:
    //         // console.log('no-op')
    //     }
    //   }
    // },
    // shuttle (frameRate) {
    //   this.mouseDown = true
    //   const shuttlex = () => {
    //     const frameInterval = frameRate > 0 ? 0.04 : -0.04
    //     const speedFactor = Math.abs(this.frameRate / frameRate)
    //     this.intervalCleared = false
    //     this.intervalShuttle = setInterval(() => {
    //       if (this.player.currentTime === 0 && frameRate < 0) {
    //         clearInterval(this.intervalShuttle)
    //         this.player.pause()
    //       } else {
    //         if (!this.intervalCleared) {
    //           this.player.currentTime += frameInterval
    //         } else {
    //           clearInterval(this.intervalShuttle) // hindre at player løper løpsk
    //         }
    //       }
    //     }, 25 * speedFactor)
    //   }
    //   setTimeout(() => {
    //     // prevent if already seeking !
    //     if (this.mouseDown) {
    //       shuttlex()
    //     }
    //   }, 300)
    // },
    // play () {
    //    this.player.play()
    // },
    // pause () {
    //   this.player.pause()
    //   clearInterval(this.intervalShuttle)
    //   this.intervalCleared = true
    // },
    getTime () {
      return this.player.currentTime
    },
    skipToTime (t) {
      this.player.currentTime = t
    },
    framesToSeconds (frames) {
      return frames / this.frameRate
    },
    skipFrames (frames) {
      this.mouseDown = false
      if (this.intervalCleared) {
        this.skipToTime(this.getTime() + this.framesToSeconds(frames))
      }
      this.player.pause()
      clearInterval(this.intervalShuttle)
      this.intervalCleared = true
    }
  }

}
</script>

<style lang="scss" scoped>
  #player-controls {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 100%;
    max-width: 500px;
  }
  #player-controls-area {
    background: rgb(199, 199, 199);
  }
  .play-icon {
    font-size: 2.3em !important;
    padding-bottom:1px !important;;
  }
</style>
