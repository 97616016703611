<template>
<div>

  <v-card
    v-if="video && video.category"
    dark outlined
    active-class="active-video"
    :to="{ name: pathName, params: { videoId: video.id }}"
  >
    <div class="d-flex flex-no-wrap justify-space-between">

      <div>
        <v-card-title
          class="subtitle-2"
          v-text="video.title"
        ></v-card-title>

        <v-card-subtitle v-text="video.category.name"></v-card-subtitle>
      </div>

      <v-avatar
        class="ma-3"
        size="60"
        tile
      >
        <v-img rel="prefetch" as="image" :src="thumb(video.fileName, video.posterTime, 720)"></v-img>
      </v-avatar>

    </div>

  </v-card>
  <div v-else>
    Kategori mangler
  </div>

</div>
</template>

<script>
// import { components } from 'aws-amplify-vue'
import { thumb } from '@/helpers/utils'

// Slette element og liste opp
// https://github.com/aws-samples/aws-amplify-vue/blob/master/src/notes/components/Notes.vue
// "https://25n6ehjrch.execute-api.eu-west-1.amazonaws.com/prod/SMPTE_00_01_30_00,w_720/gWbXziSBNs5hJRsKQ.mp4.jpg"
export default {
  props: [
      'video'
    ],
  name: 'VideoListNavVideo',
  computed: {
    pathName () {
      // console.log('router', this.$route.name)
      return this.$route.name
    }
  },
  methods: {
    thumb (fileName, time, width) {
      return thumb(fileName, time, width)
    },
    remove () {
      this.$emit('remove', this.video.id)
    },
    edit () {
      this.$emit('edit', this.video)
    }
  }
}
</script>

<style lang="scss" scoped>
  .active-video {
    background: green;
  }
</style>
