/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      rank
      name
      slug
      type
      createdAt
      updatedAt
      videos {
        items {
          id
          categoryId
          awsId
          title
          slug
          createdAt
          posterTime
          published
          fileName
          type
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      rank
      name
      slug
      type
      createdAt
      updatedAt
      videos {
        items {
          id
          categoryId
          awsId
          title
          slug
          createdAt
          posterTime
          published
          fileName
          type
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      rank
      name
      slug
      type
      createdAt
      updatedAt
      videos {
        items {
          id
          categoryId
          awsId
          title
          slug
          createdAt
          posterTime
          published
          fileName
          type
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createVideo = /* GraphQL */ `
  mutation CreateVideo(
    $input: CreateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    createVideo(input: $input, condition: $condition) {
      id
      categoryId
      awsId
      title
      slug
      createdAt
      posterTime
      published
      fileName
      type
      updatedAt
      category {
        id
        rank
        name
        slug
        type
        createdAt
        updatedAt
        videos {
          nextToken
        }
      }
      chapters {
        items {
          id
          videoId
          title
          start
          posterTime
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo(
    $input: UpdateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    updateVideo(input: $input, condition: $condition) {
      id
      categoryId
      awsId
      title
      slug
      createdAt
      posterTime
      published
      fileName
      type
      updatedAt
      category {
        id
        rank
        name
        slug
        type
        createdAt
        updatedAt
        videos {
          nextToken
        }
      }
      chapters {
        items {
          id
          videoId
          title
          start
          posterTime
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo(
    $input: DeleteVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    deleteVideo(input: $input, condition: $condition) {
      id
      categoryId
      awsId
      title
      slug
      createdAt
      posterTime
      published
      fileName
      type
      updatedAt
      category {
        id
        rank
        name
        slug
        type
        createdAt
        updatedAt
        videos {
          nextToken
        }
      }
      chapters {
        items {
          id
          videoId
          title
          start
          posterTime
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createChapter = /* GraphQL */ `
  mutation CreateChapter(
    $input: CreateChapterInput!
    $condition: ModelChapterConditionInput
  ) {
    createChapter(input: $input, condition: $condition) {
      id
      videoId
      title
      start
      posterTime
      createdAt
      updatedAt
      video {
        id
        categoryId
        awsId
        title
        slug
        createdAt
        posterTime
        published
        fileName
        type
        updatedAt
        category {
          id
          rank
          name
          slug
          type
          createdAt
          updatedAt
        }
        chapters {
          nextToken
        }
      }
    }
  }
`;
export const updateChapter = /* GraphQL */ `
  mutation UpdateChapter(
    $input: UpdateChapterInput!
    $condition: ModelChapterConditionInput
  ) {
    updateChapter(input: $input, condition: $condition) {
      id
      videoId
      title
      start
      posterTime
      createdAt
      updatedAt
      video {
        id
        categoryId
        awsId
        title
        slug
        createdAt
        posterTime
        published
        fileName
        type
        updatedAt
        category {
          id
          rank
          name
          slug
          type
          createdAt
          updatedAt
        }
        chapters {
          nextToken
        }
      }
    }
  }
`;
export const deleteChapter = /* GraphQL */ `
  mutation DeleteChapter(
    $input: DeleteChapterInput!
    $condition: ModelChapterConditionInput
  ) {
    deleteChapter(input: $input, condition: $condition) {
      id
      videoId
      title
      start
      posterTime
      createdAt
      updatedAt
      video {
        id
        categoryId
        awsId
        title
        slug
        createdAt
        posterTime
        published
        fileName
        type
        updatedAt
        category {
          id
          rank
          name
          slug
          type
          createdAt
          updatedAt
        }
        chapters {
          nextToken
        }
      }
    }
  }
`;
export const createLicense = /* GraphQL */ `
  mutation CreateLicense(
    $input: CreateLicenseInput!
    $condition: ModelLicenseConditionInput
  ) {
    createLicense(input: $input, condition: $condition) {
      id
      eier
      gyldigFraDato
      aktiv
      ressursnummer
      institusjonsType
      kontaktperson
      kontaktpersonEpost
      adresse
      postnummer
      sted
      brukerantall
      betalt
      createdAt
      updatedAt
      managers {
        items {
          id
          name
          email
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateLicense = /* GraphQL */ `
  mutation UpdateLicense(
    $input: UpdateLicenseInput!
    $condition: ModelLicenseConditionInput
  ) {
    updateLicense(input: $input, condition: $condition) {
      id
      eier
      gyldigFraDato
      aktiv
      ressursnummer
      institusjonsType
      kontaktperson
      kontaktpersonEpost
      adresse
      postnummer
      sted
      brukerantall
      betalt
      createdAt
      updatedAt
      managers {
        items {
          id
          name
          email
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteLicense = /* GraphQL */ `
  mutation DeleteLicense(
    $input: DeleteLicenseInput!
    $condition: ModelLicenseConditionInput
  ) {
    deleteLicense(input: $input, condition: $condition) {
      id
      eier
      gyldigFraDato
      aktiv
      ressursnummer
      institusjonsType
      kontaktperson
      kontaktpersonEpost
      adresse
      postnummer
      sted
      brukerantall
      betalt
      createdAt
      updatedAt
      managers {
        items {
          id
          name
          email
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createManager = /* GraphQL */ `
  mutation CreateManager(
    $input: CreateManagerInput!
    $condition: ModelManagerConditionInput
  ) {
    createManager(input: $input, condition: $condition) {
      id
      name
      email
      createdAt
      updatedAt
      license {
        id
        eier
        gyldigFraDato
        aktiv
        ressursnummer
        institusjonsType
        kontaktperson
        kontaktpersonEpost
        adresse
        postnummer
        sted
        brukerantall
        betalt
        createdAt
        updatedAt
        managers {
          nextToken
        }
      }
    }
  }
`;
export const updateManager = /* GraphQL */ `
  mutation UpdateManager(
    $input: UpdateManagerInput!
    $condition: ModelManagerConditionInput
  ) {
    updateManager(input: $input, condition: $condition) {
      id
      name
      email
      createdAt
      updatedAt
      license {
        id
        eier
        gyldigFraDato
        aktiv
        ressursnummer
        institusjonsType
        kontaktperson
        kontaktpersonEpost
        adresse
        postnummer
        sted
        brukerantall
        betalt
        createdAt
        updatedAt
        managers {
          nextToken
        }
      }
    }
  }
`;
export const deleteManager = /* GraphQL */ `
  mutation DeleteManager(
    $input: DeleteManagerInput!
    $condition: ModelManagerConditionInput
  ) {
    deleteManager(input: $input, condition: $condition) {
      id
      name
      email
      createdAt
      updatedAt
      license {
        id
        eier
        gyldigFraDato
        aktiv
        ressursnummer
        institusjonsType
        kontaktperson
        kontaktpersonEpost
        adresse
        postnummer
        sted
        brukerantall
        betalt
        createdAt
        updatedAt
        managers {
          nextToken
        }
      }
    }
  }
`;
