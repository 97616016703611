<template>
  <div id="App">
    <component :is="this.$route.meta.layout || 'div'">
      <router-view />
    </component>
  </div>
</template>

<script>
import { Hub, Auth } from 'aws-amplify'
// import { onAuthUIStateChange } from '@aws-amplify/ui-components'

export default {
  name: 'App',
  beforeCreate () {
    Hub.listen('auth', async ({ payload }) => {
      // window.console.log('New hub event', payload.event )
      if (payload.event === 'signIn') {
        const user = await Auth.currentAuthenticatedUser()
        this.$store.commit('setUser', user)
        //redirect to users original entry path:
        if (this.$store.state.entryUrl) {
          const entryUrl = this.$store.state.entryUrl
          this.$store.commit('setEntryUrl', undefined)
          this.$router.push(entryUrl)
        } else {
          this.$router.push({name: 'home'})
        }
      } else if (payload.event === 'signOut') {
        // console.log('event signed out')
        this.$store.commit('setUser', null)
        this.$router.push({name: 'auth'})
      } else {
        try {
          console.log('not signin/out, payload:', payload)
          // Auth.currentSession()
        } catch (err) {
          console.log('Auth.currentSession() fail')
        }
      }
    })
  },
  async created () {
    await this.checkUser()
  },
  methods: {
    async checkUser () {
      Auth.currentAuthenticatedUser()
      .then((user) => {
        window.console.log(`user: ${user}`)
        this.$store.commit('setUser', user)
        // console.log("state user", this.$store.state.user)
      })
      .catch( () => {
        this.$store.commit('setUser', null)
        window.console.log('user not logged in')
      })
    }

  }
};
</script>

<style lang="scss">


  .scroll-list {
    overflow-y: auto;
    height: calc(100vh - 100px);
  }
  // The root colors are in public/index.html <style>
  // :root {
  //   --amazonOrange: #1976D2 !important; /* Redefined to dark green */
  //   --lightAmazonOrange: rgb(69, 141, 212) !important;
  //   --darkAmazonOrange: rgb(15, 88, 160);
  //   --squidInk: #232F3E !important;
  //   --lightSquidInk: #31465F;
  //   --deepSquidInk: white !important;
  //   --grey: #828282;
  //   --lightGrey: #C4C4C4;
  //   --silver: #E1E4EA;
  //   --darkBlue: #31465F;
  //   --red: #DD3F5B;
  //   --white: #FFFFFF;
  //   --light-blue: #00a1c9;
  // }
</style>
