import { IMAGE_SERVER } from '@/constants' // IMAGE_DEV_SERVER, 

let IMAGESERVER = IMAGE_SERVER
// if ( process.env.NODE_ENV == 'development' ) { // dev local and on aws
//   console.log('IMAGESERVER DEV MODE')
//   IMAGESERVER = IMAGE_DEV_SERVER
// } else if (process.env.NODE_ENV == 'production') { // Test & Production
//   console.log('IMAGESERVER PROD MODE')
//   IMAGESERVER = IMAGE_PROD_SERVER
// }


export const ToSMPTEu = function (secs) {
  return ToSMPTE(secs).split(':').join('_')
}

// returnerer vanlig: 00:12:12:05
const ToSMPTE = function (secs) {
  const secNum = parseInt(secs, 10)
  const hours = Math.floor(secNum / 3600) % 24
  const minutes = Math.floor(secNum / 60) % 60
  const seconds = secNum % 60
  const SMPTEu3 = [hours, minutes, seconds]
    .map(v => v < 10 ? '0' + v : v)
    .join(':')
  let tenths = (parseFloat(secs) % 1).toFixed(2)
  tenths = tenths >= 0.96 ? 0.96 : tenths
  let frames = parseInt((tenths * 25).toFixed(0))
  frames = frames < 10 ? '00' : frames
  const SMPTE = SMPTEu3 + ':' + String(frames)
  return SMPTE
}

const secondsToMMSS = (secs) => {
  const mmss = ToSMPTE(secs).split(':')
  return `${mmss[1]}:${mmss[2]}`
}

const thumb = (fileName, time, width) => {
  const SMTPu = ToSMPTEu(time)
  const imageUrl = IMAGESERVER + 'SMPTE_' + SMTPu + ',w_' + width + '/' + fileName + '.jpg'
  return imageUrl
}

const sortByName = (arr) => {
  return arr.sort(function (a, b) {
    return a.name.localeCompare(b.name, 'no', { sensitivity: 'accent' })
  })
}
const sortByTitle = (arr) => {
  return arr.sort(function (a, b) {
    return a.title.localeCompare(b.title, 'no', { sensitivity: 'accent' })
  })
}
const sortByStartTime = (arr) => {
  return arr.sort(function (a, b) {
    return a.start - b.start
  })
}

const isPwa = () => {
  const isInStandaloneMode = () =>
    (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone)

  if (isInStandaloneMode()) {
    return true
  }
  return false
}

export {
  thumb,
  sortByName,
  sortByTitle,
  sortByStartTime,
  ToSMPTE,
  secondsToMMSS,
  isPwa
}
