import Vue from 'vue'
import Vuex from 'vuex'
// import { Storage } from 'aws-amplify';
import { thumb } from '@/helpers/utils'
import { API } from 'aws-amplify'
import * as queries from '@/graphql/queries'
import chapters from './chapters'
import awsExports from '@/aws-exports'
import Const from '@/constants'
// console.log('thumb', thumb)
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    chapters
  },
  state: {
    user: null,
    playerKeysEnabled: true,
    playerPaused: true,
    playerHeight: undefined,
    entryUrl: undefined,
    currentVideo: undefined,
    // currentChapters: undefined,
    videoLoading: false,
    player: undefined
  },

  mutations: {
    setPlayerHeight (state, height) {
      // console.log('player height', height)
      state.playerHeight = height
    },
    setPlayer (state, player) {
      // window.console.log('STATE PLAYER SET TO', player)
      state.player = player
    },
    setCurrentVideo (state, video) {
      state.currentVideo = video
    },
    setPlayerSource (state, source) { // called from action loadVideo
      state.player.source = source
    },
    setUser(state, user) {
      state.user = user
    },
    setEntryUrl(state, val) {
      state.entryUrl = val
    },
    setVideoLoading(state, val) {
      state.videoLoading = val
    },
    setCurrentChapters(state, chapters) {
      state.currentChapters = Array.from(chapters)
    },
    plyrTransport (state, { action, value }) {
      switch (action) {
        case 'play':
          state.player.play()
          break;
        case 'pause':
          state.player.pause()
          break;
        case 'forward':
          state.player.pause()
          state.player.forward(framesToSeconds(value))
          break;
        case 'rewind':
          state.player.pause()
          state.player.rewind(framesToSeconds(value))
          break;
        default:
          alert( 'An unknown value' );
      }
    }
  },
  actions: {
    // Called from dynamicVideoPlayer
    async loadVideo ({commit, state}) {
      // console.log('load video', state.currentVideo)
      // console.log('************************************** store loading video', video)
      // context.commit('setCurrentVideo', video)
      const video = state.currentVideo
      // const signedurl = await Storage.get(video.fileName)
      // const url = signedurl

      const s3videoFolder = awsExports.aws_user_files_s3_bucket + '.s3-eu-west-1.amazonaws.com/public/' // 'fagfilmb-videos152412-prod' // awsExports.aws_user_files_s3_bucket
      console.log('s3videoFolder', s3videoFolder)
      const url = 'https://' + s3videoFolder + video.fileName
      console.log('loading video url: ', url)
      
      const posterUrl = thumb(video.fileName, video.posterTime, 720)
      // console.log('posterUrl', posterUrl)
      // get poster async as imageUrl first ?
      const source = {
        type: 'video',
        'poster': posterUrl,
        sources: [
          {
            src: url,
            type: 'video/mp4'
          }
        ]
      }
      commit('setPlayerSource', source)
    },

    async setCurrentVideo ( {commit }, videoId ) {
      window.console.log('setCurrentVideo with ID', videoId)
      
      commit('setVideoLoading', true)
      let res
      try {
        res = await API.graphql({
          query: queries.getVideo,
          variables: {
            id: videoId // '6PJPxkQEG6M4Ymjo4'
          },
          // auth_mode: 'AWS_IAM'
          })
        const video = res.data.getVideo
        window.console.log('setCurrentVideo object', video)
        commit('setCurrentVideo', video)
        commit('setVideoLoading', false)
        return video
      } catch (err) {
        this.loading = false
        console.log('Video for chapters :', err)
      }
    },
    async updateCurrentVideo ( {commit }, videoId ) {
      window.console.log('updateCurrentVideowith ID', videoId)
      
      let res
      try {
        res = await API.graphql({
          query: queries.getVideo,
          variables: {
            id: videoId
          },
          // auth_mode: 'AWS_IAM'
          })
        const video = res.data.getVideo
        // window.console.log('setCurrentVideo object', video)
        commit('setCurrentVideo', video)
      } catch (err) {
        this.loading = false
        // console.log('Video for chapters :', err)
      }
    }    
  },
  getters: {
    getUser: state => {
      return state.user
    },
    getCurrentVideo: state => {
      return state.currentVideo
    },
    videoDataLoading: state => {
      const loading = !state.currentVideo
      // console.log('videoDataLoading', loading )
      return loading
    },
    currentTime: state => {
      const t = state.player.currentTime
      // window.console.log('state.player.currentTime:', state.player.currentTime)
      return t
    },
    // getChapters: state => {
    //   return state.currentChapters
    // },
    // getCurrentVideo: state => {
    //   return state.currentVideo
    // }
  },
})

function framesToSeconds (frames) {
  return frames / 25
}
