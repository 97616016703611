import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '@/graphql/queries'
import * as mutations from '@/graphql/mutations'

const chapters = {
  state: () => ({
  }),
  mutations: {
  },

  actions: {
    async updateChapter({ rootState }, { chapter, sort }) {
      console.log('updating a chapter:', chapter, 'sort: ', sort)

      delete chapter.createdAt
      delete chapter.updatedAt
      await API.graphql({
        query: mutations.updateChapter,
        variables: {
          input: {
            ...chapter
          }
        }
      })
      if (sort) {
        console.log('sort chapters')
        const chapters = rootState.currentVideo.chapters.items
        chapters.sort( (a, b) => compare(a, b))
      }
    },

    async createChapter( { commit, rootState }, chapter ) {
      if(chapter.title.length === 0) {
        chapter.title = 'tittlel mangler!'
      }

      try {
        await API.graphql(graphqlOperation(mutations.createChapter, {
          input: {
            ...chapter
          }
        }))
      } catch (err) {
        console.log('feil', err)
      }
      let res2
      try {
        res2 = await API.graphql({
          query: queries.getVideo,
          variables: {
            id: rootState.currentVideo.id
          },
          // auth_mode: 'AWS_IAM'
          })
        const video = res2.data.getVideo
        commit('setCurrentVideo', video)
      } catch (err) {
        this.loading = false
        console.log('Video for chapters :', err)
      }
      return true
    },

    async removeChapter( { rootState }, chapterId) {
      let res
      try {
        res = await API.graphql({
          query: mutations.deleteChapter,
          variables: {
            input: {
              id: chapterId
            }
          }
        })
      } catch (err) {
        console.log('feil: ', err)
      }
      const chapters = rootState.currentVideo.chapters.items
      chapters.splice(chapters.findIndex(v => v.id === chapterId), 1);      
      return res
    }
  },

  getters: {
    getChapters: (state, getters, rootState) => {
      if(rootState.currentVideo) {
        return rootState.currentVideo.chapters.items
      }
    },
    // getSortedChapters: (state, getters, rootState) => {
    //   return state.currentChapters
    // }
  }
}

function compare (a, b) {
  if (a.start < b.start) {
    return -1
  }
  if (a.start > b.start) {
    return 1
  }
  return 0
}

export default chapters
