import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '@/views/Home.vue'
// import Login from '@/views/Login'

import LayoutDefault from '@/layouts/LayoutDefault';
import LayoutAuth from '@/layouts/LayoutAuth';
// import LayoutNoNav from '@/layouts/LayoutNoNav';


// import Amplify, * as AmplifyModules from 'aws-amplify';

import AmplifyStore from '../store/index';

// import { Hub, Auth } from 'aws-amplify'
Vue.use(VueRouter)
// Vue.use(AmplifyModules) // **Amplify/Plugin**
// import store from '../store/index.js'

// console.log('store', store)
//Bli kvitt redundant nav error:
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home'),
    meta: { layout: LayoutDefault, title: 'Fagfilm admin' }
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/views/Login'),
    meta: { layout: LayoutAuth, title: 'Logg inn'}
  },
  {
    path: '/create-license',
    name: 'createLicense',
    component: () => import('@/views/CreateLicense'),
    meta: { layout: LayoutDefault, requiresAuth: true, title: 'Opprett lisens' }
  },
  {
    path: '/edit-license/:id',
    name: 'editLicense',
    component: () => import('@/views/EditLicense'),
    meta: { layout: LayoutDefault, requiresAuth: true, title: 'Rediger lisens' }
  },
  {
    path: '/create-video',
    name: 'createVideo',
    component: () => import('@/views/CreateVideo'),
    meta: { layout: LayoutDefault, requiresAuth: true, title: 'Opprett video' }
  },
  {
    path: '/rediger-video/:videoId',
    name: 'editVideo',
    component: () => import('@/views/EditVideo'),
    meta: { layout: LayoutDefault, requiresAuth: true, title: 'Redigér video' }
  },
  {
    path: '/videoer',
    name: 'videoer',
    component: () => import('@/views/Videoer'),
    meta: { layout: LayoutDefault, requiresAuth: true, title: 'Videoer' }
  },
  {
    path: '/rediger-kapitler/:videoId',
    name: 'editChapters',
    component: () => import('@/views/Chapters'),
    meta: { layout: LayoutDefault, requiresAuth: true, title: 'Redigér kapitler' } // LayoutDefault
  },
  {
    path: '/kategorier',
    name: 'categories',
    component: () => import('@/views/Categories'),
    meta: { layout: LayoutDefault, requiresAuth: true, title: 'Kategorier' }
  },
  {
    path: '/lisenser',
    name: 'licenses',
    component: () => import('@/views/Licenses'),
    meta: { layout: LayoutDefault, requiresAuth: true, title: 'Lisenser' }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeResolve(async (to, from, next) => {
  // console.log('to', to.path)
  // AmplifyStore.commit('enableKeys');
  let user = AmplifyStore.state.user

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (user) {
      // console.log('user is present - going next')
      next();
    } else {
      AmplifyStore.commit('setEntryUrl', to.path) // store entry url before redirect
      // console.log('setEntryUrl set to', AmplifyStore.state.entryUrl)
      next('/auth');
    }
  }

  next()
})

export default router
