<template>
  <v-app id="fagfilm-admin">
    <v-navigation-drawer
      v-model="drawer"
      app
      mobile-breakpoint="1023"
    >
      <v-list nav>

        <v-list-item link :to="{ name: 'home'}">
          <v-list-item-action>
            <v-icon id="home-icon">mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'licenses'}">
          <v-list-item-action>
            <v-icon id="license-icon">mdi-table-of-contents</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Lisenser</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'createLicense'}">
          <v-list-item-action>
            <v-icon id="license-icon">mdi-ticket-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Opprett lisens</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'videoer'}">
          <v-list-item-action>
            <v-icon id="video-icon">mdi-table-of-contents</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Videoer</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'createVideo'}">
          <v-list-item-action>
            <v-icon id="video-icon">mdi-video-plus</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Opprett video</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'categories'}"> 
          <v-list-item-action>
            <v-icon id="category-icon">mdi-shape</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Kategorier</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logout" v-if="loggedIn">
          <v-list-item-action>
            <v-icon id="logout-icon">mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logg ut</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="goTo('auth')" v-else >
          <v-list-item-action>
            <v-icon id="login-icon">mdi-login</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logg inn</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="#c00"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
      <v-toolbar-title>{{$route.meta.title ? $route.meta.title : ''}}</v-toolbar-title>

      <template v-slot:extension v-if="$route.name === 'editChapters' || $route.name === 'editVideo'">
        <v-btn text :disabled="$route.name !== 'editChapters'" @click="goTo('editVideo')">Rediger video info</v-btn>
        <v-btn text :disabled="$route.name !== 'editVideo'" @click="goTo('editChapters')">Rediger kapitler</v-btn>
      </template>


      <v-spacer></v-spacer>

      <v-btn icon @click.stop="drawerRight = !drawerRight" v-if="rightNav">
        <v-icon>mdi-view-list</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- ONLY SHOW WHEN EDITING CHAPTERS -->

    <keep-alive>
      <v-navigation-drawer v-if="rightNav"
        v-model="drawerRight"
        app
        right
        mobile-breakpoint="1023"
      >
        <VideoNavList v-if="videoList"/>
        <LicenseNavList v-if="licenseList" />
      </v-navigation-drawer>
    </keep-alive>

    <v-main>
      <v-container
        fluid
        pa-0
      >

        <div id="router-view-wrap">
            <VideoPlayer v-if="$route.name === 'editChapters' || $route.name === 'editVideo'"/>
            <PlayerControls v-if="$route.name === 'editChapters' || $route.name === 'editVideo'"/>
          <slot />
        </div>

      </v-container>
    </v-main>
    <v-footer
      color="#c00"
      app
    >
      <span class="white--text">Fagfilm&copy; 2020</span>
    </v-footer>
  </v-app>
</template>

<script>
  import VideoNavList from '@/components/videos/videoNavList'
  import LicenseNavList from '@/components/licenses/LicenseNavList'
  import { Auth } from 'aws-amplify';
  import VideoPlayer from '@/components/common/dynamicVideoPlayer'
  
  import PlayerControls from '@/components/common/playerControls'

  export default {
    name: 'LayoutDefault',
    created () {
      // Change theme
      this.$vuetify.theme.dark = true
    },
    props: {
      source: String,
    },
    data() {
      return {
        drawer: false,
        drawerRight: false,
        hMenu: false
      }
    },
    components: {
      VideoNavList,
      LicenseNavList,
      VideoPlayer,
      PlayerControls
    },
    methods: {
      goTo(routeName) {
        this.$router.push( {name: routeName})
      },
      goToVideo(video) {
        this.$router.push( {name: video})
      },
      async logout () {
        console.log('logging out')
        await Auth.signOut()
        this.$store.dispatch('setUser', null)
      }
    },
    computed: {
      loggedIn () {
        return this.$store.state.user
      },
      videoList () {
        return this.$route.name === 'editChapters' || this.$route.name === 'editVideo'
      },
      licenseList () {
        return this.$route.name === 'editLicense'
      },
      rightNav () {
        return this.videoList || this.licenseList
      }
    },
    mounted () {
      let elHtml = document.getElementsByTagName('html')[0]
      elHtml.style.overflowY = 'hidden'
    },
    destroyed: function() {
      let elHtml = document.getElementsByTagName('html')[0]
      elHtml.style.overflowY = null
    }
  }

  /*

  EKSEMPELBRUK: color: var(--v-warning-base);
  primary: '#1976D2',
  secondary: '#424242',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',

  */


 /*
  map-get($grey, darken-4)
 */

</script>

<style lang="scss">
  #home-icon {
    color: #e60000;
    font-size: 2em;
  }
  #license-icon {
    color: #cc33ff;
    font-size: 2em;
  }
  #video-icon {
    color: #4da6ff;
    font-size: 2em;
  }
  #category-icon {
    color: #ff6633;
    font-size: 2em;
  }
  #login-icon {
    color: #66cc00;
    font-size: 2em;
  }
  #logout-icon {
    color: #ff3333;
    font-size: 2em;
  }
  // #fagfilm-admin {
  //   min-width: 300px
  // }
</style>
