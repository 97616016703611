<template>
<v-card>
  <v-card-title>
    <v-text-field
      v-model="search"
      append-icon="mdi-file-find"
      label="Søk"

      hide-details

    ></v-text-field>
  </v-card-title>

  <v-data-table
    :headers="headers"
    :items="licenses"
    :search="search"
    :items-per-page="1500"

    :loading="loading"
    hide-default-footer
    mobile-breakpoint="4000"
  >

    <template v-if="res.errors && res.errors.length > 0" v-slot:top>
      <p>Noe gikk galt: {{res.errors[0].message}}</p>
    </template>


    <template v-slot:item="{ item }">
      <LicenseNavItem :license="item" />
    </template>

    <template v-if="true" v-slot:progress>
      <v-progress-linear color="blue" :height="10" indeterminate></v-progress-linear>
    </template>

  </v-data-table>
</v-card>
</template>

<!-- BRUK TABLES! -->
<script>

import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '@/graphql/queries'
import LicenseNavItem from '@/components/licenses/LicenseNavItem'

// let deleteVideoSubscription, createdVideoSubscription

export default {
 name: 'VideoList',
 data () {
   return {
    search: '',
    headers: [
       {
         text: 'Eier',
         value: 'eier',
       },
       {
         text: 'Aktiv',
         value: 'aktiv',
       },
       {
         text: 'Kode',
         value: 'id',
       }
     ],
     res: {},
     loading: true,
     licenses: [],
     license: null
   }
 },
 components: {
   LicenseNavItem
 },
 created () {
  this.list()
 },

 methods: {
   async list () {
     try {
      const result = await API.graphql(
        graphqlOperation(queries.listLicenses, {
          limit: 5000
        })
      )
      console.log('result', result)
      this.res = result
      this.data = result.data.listLicenses.items
      this.loading = result.loading
      this.licenses = result.data.listLicenses.items
     } catch (e) {
       console.log(e)
       this.res = e
     }
   }
 }

}
</script>

<style>

</style>
