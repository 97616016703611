import { render, staticRenderFns } from "./playerControls.vue?vue&type=template&id=6b8393bf&scoped=true&"
import script from "./playerControls.vue?vue&type=script&lang=js&"
export * from "./playerControls.vue?vue&type=script&lang=js&"
import style0 from "./playerControls.vue?vue&type=style&index=0&id=6b8393bf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b8393bf",
  null
  
)

export default component.exports