import awsmobile from '@/aws-exports'

console.log('awsmobile', awsmobile)
console.log('ENV:', process.env.NODE_ENV)

// const env = process.env.NODE_ENV
let imageServer = 'https://x02ka2g58c.execute-api.eu-west-1.amazonaws.com/prod/'

// switch(env) {
//   case 'development':
//       imageServer = 'https://x02ka2g58c.execute-api.eu-west-1.amazonaws.com/prod/'
//       break;
//   case 'production':
//       imageServer = 'https://x02ka2g58c.execute-api.eu-west-1.amazonaws.com/prod/'
//       break;
//   case 'test':
//       imageServer = 'https://x02ka2g58c.execute-api.eu-west-1.amazonaws.com/prod/'
//       break;
//   default:
//     throw new Error('Environment støttes ikke. Env:', env)
// }

// export const IMAGE_SERVER = 'https://d1j2lkoxx8uycr.cloudfront.net/'
export const IMAGE_SERVER = imageServer
// export const VIDEO_SERVER = awsmobile.aws_user_files_s3_bucket // fagfilmb-videos212429-production

export const VIDEO_SERVER = 'fagfilmb-videos152412-prod'

// For å endre hvor FFFMPEG henter video fra må videoUrl i getThumb i fagfilm-thubs endres
// For å endre server sjekk helpers/utils.js

// https://ihzkd1yod9.execute-api.eu-west-1.amazonaws.com/prod/SMPTE_00_00_01_13,w_300/faagfilm-test-EDjwYu0yF.mp4.jpg


// https://ihzkd1yod9.execute-api.eu-west-1.amazonaws.com/prod/SMPTE_00_02_26_00,w_300/d90983b0-ebc3-4d83-8a6e-d9e956c4b365.mp4.jpg

// Front end thumbs url: http://d1j2lkoxx8uycr.cloudfront.net/
// back end url: https://x02ka2g58c.execute-api.eu-west-1.amazonaws.com/prod/