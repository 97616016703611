<template>
  <v-card
    dark outlined
    active-class="active-license"
    :to="{ name: 'editLicense', params: { id: license.id }}"

  >
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title
          class="subtitle-2"
          v-text="license.eier"
        ></v-card-title>
        <v-card-subtitle v-text="description"></v-card-subtitle>
      </div>
    </div>
  </v-card>

</template>

<script>

// Slette element og liste opp
// https://github.com/aws-samples/aws-amplify-vue/blob/master/src/notes/components/Notes.vue

export default {
  props: ['license'],
  name: 'LicenseNavItem',
  computed: {
    description () {
      const skolekode = this.license.id
      const aktiv = this.license.aktiv ? 'Ja' : 'Nei'
      console.log('aktiv', this.license.aktiv)
      return 'Skolekode: ' + skolekode + ', Aktiv: ' + aktiv
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .active-license {
    background:#1E88E5;
  }
</style>
