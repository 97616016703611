// import '@aws-amplify/ui-components';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

// import '@aws-amplify/ui-vue';
import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// import VuePlyr from 'vue-plyr'
import 'plyr/dist/plyr.css'
import 'vue-resize/dist/vue-resize.css'
import VueResize from 'vue-resize'
// import AsyncComputed from 'vue-async-computed'
import { Amplify, Auth } from 'aws-amplify';  // Bruker 3.4.3 before upgrade
import awsconfig from './aws-exports';


// window.LOG_LEVEL = 'DEBUG';


Auth.configure(awsconfig);
Amplify.configure(awsconfig)


// Vue.config.productionTip = false

// ui-components:
Vue.config.ignoredElements = [/amplify-\w*/];
applyPolyfills().then(() => {
  defineCustomElements(window);
});

// Vue.use(AsyncComputed)
Vue.use(VueResize)

// aws_user_files_s3_bucket


// Vue.use(VuePlyr, {
//
// })

// Vue.mixin({
//   created() {
//     console.log('[created] ' + this.$options.name)
//   },
// });
// Vue.config.debug = true; Vue.config.devtools = true;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')